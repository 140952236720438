import * as React from "react"
// global components
import Layout from "../components/layout"
import Seo from "../components/seo"
// page components
import Facts from "../components/about/facts"
import Timeline from "../components/about/timeline"
import MyStory from "../components/about/my-story"

import JSONData from "../data/texts.json"

const page = JSONData.pages.about

const AboutPage = () => (
  <Layout>
    <Seo title="About" />
    <MyStory texts={page.story}/>
    <Facts texts={page.facts}/>
    <Timeline texts={page.timeline}/>
  </Layout>
)

export default AboutPage
