import React from 'react';

const Timeline = ({ texts }) => {
  return (
    <div className="container mx-auto w-full h-full">
      <div className="text-2xl pb-4 text-center">
        {texts.title}
      </div>
      <div className="relative wrap overflow-hidden p-10 h-full">
        <div className="border-2-2 absolute border-opacity-20 border-gray-700 h-full border md:left-1/2 left-16"></div>
          {texts.items.map((item, i) => {
            return i % 2 === 0 ? <LeftTimelineItem text={item} key={`timeline-${i}`}/>: <RightTimelineItem text={item} key={`timeline-${i}`}/>
          })}
      </div>
    </div>
  )
}

const LeftTimelineItem = ({text}) => (
  <div className="mb-8 flex justify-between md:flex-row-reverse items-center w-full left-timeline">
    <div className="hidden md:block order-1 w-5/12"></div>
    <div className="p-2 z-20 flex items-center order-1 bg-gray-800 shadow-xl rounded-full mr-6 md:mr-0">
      <span className="mx-auto text-white font-semibold text-lg">{text.date}</span>
    </div>
    <div className="order-1 bg-sky-300 rounded-lg shadow-xl md:w-5/12 px-6 py-4">
      <h3 className="mb-3 font-bold text-gray-700 text-xl">{text.title}</h3>
      <p className="text-sm font-medium leading-snug tracking-wide text-gray-700 text-opacity-100">{text.text}</p>
      <MoreButton link={text.link}/>
    </div>
  </div>
)

const RightTimelineItem = ({text}) => (
  <div className="mb-8 flex justify-between items-center w-full right-timeline">
    <div className="hidden md:block order-1 w-5/12"></div>
    <div className="p-2 z-20 flex items-center order-1 bg-gray-800 shadow-xl rounded-full mr-6 md:mr-0">
      <span className="mx-auto text-white font-semibold text-lg">{text.date}</span>
    </div>
    <div className="order-1 bg-sky-300 rounded-lg shadow-xl md:w-5/12 px-6 py-4">
      <h3 className="mb-3 font-bold text-gray-700 text-xl">{text.title}</h3>
      <p className="text-sm leading-snug tracking-wide text-gray-700 text-opacity-100">{text.text}</p>
      <MoreButton link={text.link}/>
    </div>
  </div>
)

const MoreButton = ({link}) => (
  link ? <a href={link} class="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded-full no-underline">Read more</a> : ""
)

export default Timeline
