import React from 'react';

const Facts = ({ texts }) => {
  return (
    <div className="container mx-auto w-full h-full pb-4 mb-4">
      <div className="relative wrap overflow-hidden p-10 h-full">
        <div className="text-2xl pb-4 mb-4 text-center">
          {texts.title}
        </div>
        <div>
          <ul class="mb-8 space-y-4 text-left">
            {texts.items.map((item, i) => (
              <li class="flex items-center space-x-3" key={`fact-${i}`}>
                <svg class="w-6 h-6 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Facts
