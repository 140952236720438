import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

const MyStory = ({ texts }) => {
  return (
    <div className="container mx-auto w-full h-full mb-4 pb-4">
      <div className="md:grid md:grid-cols-2 gap-4 h-full justify-items-stretch p-10 px-6">
        <div>
          <div className="text-4xl pb-4 font-semibold">
            {texts.title}
          </div>
          <div className="pr-4 text-lg">
            {texts.items.map((item, i) => (
              <div className="py-4" key={`story-${i}`}>
                {item}
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-end">
          <StaticImage
            src="../../images/wake.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            className="w-full md:w-4/5 z-50 rounded-3xl"
          />
        </div>
      </div>
    </div>
  )
}

export default MyStory
